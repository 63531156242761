import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
// import { Tags } from '@tryghost/helpers-gatsby'
// import { readingTime as readingTimeHelper } from '@tryghost/helpers'

const PostCard = ({ post }) => {
    const url = `${post.frontmatter.slug}`
    // const readingTime = readingTimeHelper(post)
    const readingTime = `${post.timeToRead} min read`

    // don't use authors yet
    const primary_author = post.primary_author || {
        'name': 'Fed',
        'profile_image': null
    };

    return (
        <Link to={url} className="post-card">
            <header className="post-card-header">
                {post.frontmatter.imageUrl &&
                    <div className="post-card-image" style={{
                        backgroundImage: `url(${post.frontmatter.imageUrl})` ,
                    }}></div>}
                {/*
                    {post.tags && <div className="post-card-tags"> <Tags post={post} visibility="public" autolink={false} /></div>}
                */}

                {/*{post.featured ? (
                    <span>Featured</span>
                ) : ''}*/}
                <h2 className="post-card-title">{post.frontmatter.title}</h2>
            </header>
            <section className="post-card-excerpt">{post.frontmatter.description}</section>
            <footer className="post-card-footer">
                <div className="post-card-footer-left">
                    <div className="post-card-avatar">
                        {primary_author.profile_image ?
                            <img className="author-profile-image" src={primary_author.profile_image} alt={primary_author.name}/> :
                            <img className="default-avatar" src="/images/icons/avatar.svg" alt={primary_author.name}/>
                        }
                    </div>
                    <span>{ primary_author.name }
                        <br/><span style={{fontSize: '0.7em', lineHeight: '0.7em'}}>{post.frontmatter.date}</span>
                    </span>
                </div>
                <div className="post-card-footer-right">
                    <div>{readingTime}</div>
                </div>
            </footer>
        </Link>
    )
}

PostCard.propTypes = {
    post: PropTypes.shape({
        excerpt: PropTypes.string.isRequired,
        frontmatter: PropTypes.shape({
            slug: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
            imageUrl: PropTypes.string.isRequired,
            // profile_image: PropTypes.string,
        }).isRequired,
    }).isRequired,
}

export default PostCard
