/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

import coverImage from "../../images/branding/cover.png"

function SEO({ description, lang, meta, title, dontIndex, imageUrl, type }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            siteUrl
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description;

  var url = ''
  if (typeof window !== 'undefined'){
    url = `${site.siteMetadata.siteUrl}${window.location.pathname}`
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s`}
    >
      <link rel="icon" href="/branding/icon.png" type="image/x-icon"/>
      <link rel="canonical" href={url} />
      <meta name="description" content={metaDescription} />
      
      <meta name="og:title" content={title} />
      <meta name="og:url" content={url} />
      <meta name="og:description" content={metaDescription} />
      <meta name="og:type" content={type || "website"} />
      {imageUrl ? (
        <meta name="og:image" content={imageUrl} />
      ) : (
        <meta name="og:image" content={coverImage} />
      )}
      
      <meta name="twitter:card" content="summary" />
      {site.siteMetadata.author ? (
        <meta name="twitter:creator" content={site.siteMetadata.author} />
      ) : ''}
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={metaDescription} />

      {dontIndex ? (
        <meta name="robots" content="noindex, follow" />
      ) : (
        <meta name="robots" content="index, follow" />
      )}
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO